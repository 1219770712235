import React from 'react';
import {notification, Spin} from 'antd';
import Store from './library/store';
import Logger from './library/logger';
import {parseUrlParams} from './library/url';
import Fetcher from './library/fetcher';
import Config from './config';
import AppLayout from './layouts/index';


window.Log = Logger;

export default class App extends React.Component {
    constructor(props) {
        super(props);
        const token = Store.get(Config.TOKEN.AUTH);
        this.state = {
            loading: true,
            user: {},
            token,
        };
    }


    loadUserProfile = () => {
        return new Promise((resolve, reject) => {
            Fetcher('/oauth/verify', 'GET').then(res => {
                const data = res.data || {};
                if (!data.id) {
                    this.showAuthMessageError();
                } else {
                    window.user = data;
                    this.setState({isAuth: true, loading: false, user: data});
                }
            }).catch(e => {
                reject(e);
                this.showAuthMessageError(`Your session has been expired`, 3000);
            });
        });
    }

    oauthRedirect = () => {
        let url = `${Config.OAUTH.URL}?callback=${Config.OAUTH.PROJECT_PATH}&front=1`;
        window.location.href = url;
    }

    getTokens = (key = '') => {
        return new Promise((resolve, reject) => {
            Fetcher(`/oauth/tokens`, 'POST', {key}).then(res => {
                const data = res.data || {};
                if (data.tokens && data.tokens.access) {
                    Store.set(Config.TOKEN.AUTH, data.tokens.access);
                    Store.set(Config.TOKEN.REFRESH, data.tokens.refresh);
                    if (Store.get('REDIRECT_URL')) {
                        let redirect_url = Store.get('REDIRECT_URL');
                        Store.remove('REDIRECT_URL');
                        window.location.href = redirect_url;
                    } else {
                        window.location.href = '/';
                    }
                } else {
                    this.showAuthMessageError();
                }
            }).catch(e => {
                window.Log.error(`error send request`, e);
                this.showAuthMessageError();
            });
        });
    }

    showAuthMessageError = (message = 'Authorization error, you will redirect to main page soon', timeout = 3000, type = 'error') => {
        if (notification[type]) {
            notification[type]({message});
        }

        Store.remove(Config.TOKEN.AUTH)
        Store.remove(Config.TOKEN.REFRESH)
        setTimeout(() => {
            window.location.href = '/';
        }, timeout);
    }

    componentDidMount = async () => {
        const request = parseUrlParams();
        try {
            if (request && request.key) {
                await this.getTokens(request.key);
            } else {
                if (this.state.token) {
                    await this.loadUserProfile();
                } else {
                     this.setState({loading: false});
                     Logger.info(`user does not have authorization`, {});
                     Store.set(`REDIRECT_URL`, window.location.href);
                     this.oauthRedirect();
                }
            }                          
        } catch (e) {
            Logger.error(`error execute promise`, e)
        }
    }


    render() {
        const {loading} = this.state;
        if (loading) {
            return <Spin className={`app_loading`} spinning={loading} tip={`Loading`} size={`big`}/>
        }
        return <AppLayout />;
    }
}