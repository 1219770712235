function isLocal() {
    return window.location.hostname.indexOf("localhost") + 1 > 0;
}

export default {
    FETCH_AUTH_HEADER: 'Authorization',
    TOKEN: {
        AUTH: 'rtstaff_access',
        REFRESH: 'rtstaff_refresh',
    },
    OAUTH: {
        URL: `http://auth.dev.rt.com`,
        PROJECT_PATH: isLocal() ? `http://localhost:3000` : `https://backend-chtd.rt.com`,
    },
    API: isLocal() ? 'http://localhost:9300/backend/api' : '/backend/api',
    STATIC: isLocal() ? 'http://localhost:9300/' : 'https://chtd.rt.com/chtd-files/',
    EXPORT_URL: isLocal() ? 'http://localhost:9300/backend/api/export/ade272b2794de1320f7dbf11fa7df4db' : 'https://chtd.rt.com/backend/api/export/ade272b2794de1320f7dbf11fa7df4db',
    EXPORT_URL_JSON: isLocal() ? 'http://localhost:9300/backend/api/export_json/ade272b2794de1320f7dbf11fa7df4db' : 'https://chtd.rt.com/backend/api/export_json/ade272b2794de1320f7dbf11fa7df4db',
}