const styles = {
    'error': `padding: 4px;background: #FF0000;color:#FFF;border-radius: 3px;`,
    'warning': `padding: 4px;background: rgb(255, 85, 0);color:#FFF;border-radius: 3px;`,
    'info': `padding: 4px;background: rgb(16, 142, 233);;color:#FFF;border-radius: 3px;`,
    'success': `padding: 4px;background: rgb(135, 208, 104);color:#FFF;border-radius: 3px;`,
};


function logMessage(type, message, data) {
    type = Object.keys(styles).indexOf(type) + 1 === 0 ? `info` : type;
    console.log(`%c${type}`, styles[type], `[${(new Date())}] ${message}`, data);
}


export default {
    error: (message, data) => {
        logMessage(`error`, message, data);
    },
    warning: (message, data) => {
        logMessage(`warning`, message, data);
    },
    info: (message, data) => {
        logMessage(`info`, message, data);
    },
    success: (message, data) => {
        logMessage(`success`, message, data);
    }
}