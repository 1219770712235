import React from 'react';
import {Button, Checkbox, Input, message, PageHeader, Popconfirm, Table, Tag, Modal} from 'antd';
import { Link } from 'react-router-dom';
import Fetcher from './../../library/fetcher';
import Moment from 'moment';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';
import Config from './../../config';

export class QuestionsIndex extends React.Component {

    columns = [
        {
            title: '#',
            key: 'number',
            align: 'center',
            render: r => {
               return r.number || ''
            }
        },
        {
            title: `Пользователь`,
            key: 'user',
            width: '120px',
            render: r => {
                if (r.user && r.user.id) {
                    return <Link to={`/profiles/${r.user.id}`}>{r.user.full_name}</Link>
                }
                return `[не установлен]`;
            }
        },
        {
            title: `Дата загрузки`,
            key: 'created_at',
            render: r => Moment(r.createdAt).format('YYYY-MM-DD hh:mm:ss'),
        },
        {
            title: 'Видео',
            key: 'cover',
            render: r => {

                if (r.covers && Array.isArray(r.covers) && r.covers.length > 0) {
                    let cover = r.cover_file;
                    if (!cover) {
                        cover = r.covers[0];
                    }
                    return <img
                        src={`${Config.STATIC}${cover}`}
                        className={`qcover`}
                        alt={`video cover`}
                        onClick={() => {
                            this.showVideoModal(r);
                        }}
                    />
                }
                return null;
            }
        },
        {
            title: 'Описание',
            key: 'shortcut',
            render: r => {
                return r.shortcut || "";
            }
        },
        {
            title: `Проверен`,
            key: `approved`,
            align: 'center',
            render: r =>
                <Checkbox
                    checked={r.approved}
                    onChange={(e) => {
                        this.updateQuestions(r.id, 'approved', e.target.checked)
                    }}
                />
        },
        {
            title: `Обработка`,
            key: 'transcode_status',
            render: r => this.getStatus(r.transcode_status || `not started`),
        },
        {
            title: '',
            align: 'center',
            key: 'actions',
            render: r => {
                return (
                    <div className={`actions_wrp`}>
                        <Button type={`primary`} icon={<EditOutlined/>} onClick={() => this.props.history.push('/questions/' + r.id)}/>
                        <Popconfirm title={`Вы уверены, что хотите удалить вопрос?`} onConfirm={() => this.deleteItem(r.id)}>
                            <Button type={`danger`} icon={<DeleteOutlined/>}/>
                        </Popconfirm>
                    </div>
                );
            }
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            page: 1,
            limit: 30,
            search: '',
            loading: false,
            video_title: ``,
            video_path: ``,
        };
    }

    showVideoModal = (item) => {
        this.setState({
            video_title: `Видео от ${item.user.email}`,
            video_path: `${Config.STATIC}${item.video_file}`
        })
    }

    closeVideoModal = () => {
        this.setState({ video_title: '', video_path: ''});
    }

    deleteItem = (id = '') => {
        this.setState({ loading: true }, () => {
            Fetcher(`questions/${id}`, `DELETE`).then(res => {
                this.loadList();
            }).catch(e => {
                message.info(`Вопрос уже был ранее удален`);
                this.setState({ loading: false });
            })
        })
    }

    getStatus = (status = 'not started') => {
        let color = '';
        switch(status) {
            case 'error': color = 'red'; break;
            case 'finished': color = 'green'; break;
            case 'start': color = 'green'; break;
            default: color = 'gray'; break;
        }
        return <Tag color={color}>{status}</Tag>
    }


    updateQuestions = (id, field, status) => {
        this.setState({ loading: true }, () => {
            let sendData = {};
            sendData[field] = status;
            Fetcher(`/questions/${id}`, `PUT`, sendData).then(res => {
                this.loadList();
            }).catch(e => {
                message.error(`Ошибка обновления вопрос`);
                console.error(e);
                this.setState({ loading: false });
            });
        })
    }

    loadList = () => {
        this.setState({loading: true}, () => {
            const {page, limit, search} = this.state;
            Fetcher(`questions?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}`, `GET`).then(res => {
                const data = res.data || {};
                this.setState({
                    list: data.list || [],
                    total: data.total || 0,
                    loading: false,
                })
            }).catch(e => {
                message.error(`profiles load error`);
                console.error(e);
                this.setState({loading: false, list: []});
            });
        });
    }


    onPageChange = (page) => {
        this.setState({ page }, () => {
            this.loadList();
        })
    }

    onSearch = (search) => {
        this.setState({ page: 1, search }, () => {
            this.loadList();
        })
    }

    componentDidMount() {
        this.loadList();
    }

    render() {
        const {list, loading, video_title, video_path } = this.state;
        return (
            <div className={`controller questions`}>
                <Modal title={video_title} visible={video_path !== ""} onCancel={this.closeVideoModal} footer={null}>
                    <video src={video_path} controls style={{width: '100%', height: `auto`}}/>
                </Modal>
                <PageHeader title={`Вопросы пользователей`} className={`page-header`}/>
                <Table
                    bordered
                    title={() => {
                        return (
                            <div className={`table_actions`}>
                                <Button type={`primary`} onClick={this.createOutputRedirect}>Создать</Button>
                                <Input.Search placeholder={`Введите названи`} onSearch={this.onSearch}/>
                            </div>
                        );
                    }}
                    columns={this.columns}
                    rowKey={row => row.id}
                    dataSource={list}
                    loading={loading}
                    pagination={{
                        pageSize: this.state.limit,
                        defaultCurrent: this.state.page,
                        total: this.state.total,
                        onChange: this.onPageChange,
                        current: this.state.page,
                    }}
                />
            </div>
        );
    }
}