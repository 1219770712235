import React from 'react';
import {Button, DatePicker, Form, Input, message, PageHeader, Spin} from "antd";
import Fetcher from './../../library/fetcher';
import Moment from 'moment';

const checkYoutube = (rule, value) => {
    if (!value) {
        return Promise.resolve();
    }

    let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    let match = value.match(regExp);
    if (match && match[7].length === 11) {
        return Promise.resolve();
    }
    return Promise.reject(`Введена неправильная ссылка`);
}


export class OutputCreateController extends React.Component {
    constructor(props) {
        super(props);
        let id = this.props.match && this.props.match.params && this.props.match.params.id ? this.props.match.params.id : false;
        this.state = {
            loading: false,
            id,
            item: {},
        };
    }


    onFinish = (values) => {
        this.setState({loading: true}, () => {
            if (this.state.id !== false) {
                Fetcher(`/outputs/${this.state.id}`, `PUT`, values).then(res => {
                    message.success(`Выпуск успешно обновлен`);
                    let item = {...this.state.item, ...values};
                    this.setState({ loading: false, item });
                }).catch(e => {
                    console.error(`error create output`, e);
                    message.error(`Ошибка обновления выпуска, обратитесь пожалуйста к разработчикам`);
                    this.setState({loading: false});
                });
            } else {
                Fetcher(`/outputs`, `POST`, values).then(res => {
                    message.success(`Выпуск успешно создан`);
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 2000);
                }).catch(e => {
                    console.error(`error create output`, e);
                    message.error(`Ошибка создания выпуска, обратитесь пожалуйста к разработчикам`);
                    this.setState({loading: false});
                });
            }


        })
    }

    loadItem = () => {

        this.setState({loading: true}, () => {
            Fetcher(`outputs/${this.state.id}`, `GET`).then(res => {
                const item = res.data || {};
                this.setState({
                    loading: false,
                    item,
                })
            }).catch(e => {
                this.setState({loading: false});
                message.error(`Ошибка загрузки выпуска`);
            });
        })
    }

    componentDidMount() {
        if (this.state.id !== false) {
            this.loadItem();
        }
    }


    render() {
        const data = {
            title: this.state.item.title || "",
            questions_to: this.state.item.questions_to ? Moment(this.state.item.questions_to) : "",
            published_at: this.state.item.published_at ? Moment(this.state.item.published_at) : "",
            youtube_video_link: this.state.item.youtube_video_link || "",
        }


        return (
            <div className={`controller output_create`}>
                <PageHeader title={this.state.id === false ? `Создание выпуска` : `Обновление выпуска`} className={`page-header`} onBack={() => {
                    window.history.back();
                }}/>
                <div className="panel">
                    <Spin spinning={this.state.loading}>
                        {this.state.loading === false && (
                            <Form method={`POST`} layout={`vertical`} name={`create_output`} onFinish={this.onFinish}
                                  initialValues={data}>
                                <Form.Item label={`Название`} name={`title`}
                                           rules={[{required: true, message: `Введите название выпуска`}]}>
                                    <Input placeholder={`Введите название выпуска`} defaultValue={data.title}/>
                                </Form.Item>
                                <Form.Item label={`Вопросы собираются до`} name={`questions_to`}
                                           rules={[{required: true, message: `Введите название выпуска`}]}>
                                    <DatePicker placeholder={`Выберите дату`} style={{width: '100%'}}/>
                                </Form.Item>
                                <Form.Item label={`Дата публикации`} name={`published_at`}
                                           rules={[{required: true, message: `Введите название выпуска`}]}>
                                    <DatePicker placeholder={`Выберите дату`} style={{width: '100%'}} showTime/>
                                </Form.Item>
                                <Form.Item label={`Ссылка на youtube c выпуском`} name={`youtube_video_link`}
                                           rules={[{validator: checkYoutube}]}>
                                    <Input placeholder={`Вставьте ссылку на youtube`}/>
                                </Form.Item>
                                <Button type={`primary`} htmlType={`submit`}>{this.state.id === false ? `Создать` : `Обновить`}</Button>
                            </Form>
                        )}
                    </Spin>
                </div>
            </div>
        );
    }
}