import React from 'react';
import {Avatar, Button, Checkbox, Input, message, PageHeader, Table} from 'antd';
import Fetcher from './../../library/fetcher';
import Moment from 'moment';
import {EyeOutlined} from '@ant-design/icons';

export class ProfileIndex extends React.Component {

    columns = [
        {
            title: 'Аватар',
            key: 'avatar',
            width: '54',
            align: 'center',
            render: r => {
                if (r.image !== "") {
                    return <Avatar src={r.image} size={40}/>
                }
                return null;
            }
        },
        {
            title: `Email`,
            key: 'email',
            render: r => r.email,
        },
        {
            title: 'Имя',
            key: 'name',
            render: r => r.full_name,
        },
        {
            title: 'Активность',
            key: 'active',
            width: '30',
            align: 'center',
            render: r => <Checkbox
                checked={r.active}
                onChange={(e) => {
                    this.updateProfileActivity(r.id, e.target.checked);
                }}
            />,
        },
        {
            title: 'Дата регистрации',
            key: 'created_at',
            width: '30',
            align: 'center',
            render: r => Moment(r.createdAt).format('YYYY-MM-DD hh:mm:ss'),
        },
        {
            title: 'Действия',
            key: 'actions',
            align: 'center',
            render: r => {
                return <Button type={`primary`} icon={<EyeOutlined/>}
                               onClick={() => this.props.history.push('/profiles/' + r.id)}/>
            }
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            page: 1,
            limit: 30,
            search: '',
            loading: false,
        };
    }

    updateProfileActivity = (id, active) => {
        this.setState({ loading: true }, () => {
            Fetcher(`profiles/${id}`, `PUT`, {active}).then(res => {
                let list = this.state.list.map(i => {
                    let a = {...i};
                    if (a.id === id) {
                        a.active = active;
                    }
                    return a;
                });
                this.setState({ list, loading: false});
            }).catch(e => {
                message.error(`Ошибка обновления профиля`);
                console.log(e);
                this.setState({ loading: false });
            });
        })
    }


    loadProfiles = () => {
        this.setState({loading: false}, () => {
            const {page, limit, search} = this.state;
            Fetcher(`profiles?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}`, `GET`).then(res => {
                const data = res.data || {};
                this.setState({
                    list: data.list || [],
                    total: data.total || 0,
                    loading: false,
                })
            }).catch(e => {
                message.error(`profiles load error`);
                console.error(e);
                this.setState({loading: false, list: []});
            });
        });
    }


    onPageChange = (page) => {
        this.setState({ page }, () => {
            this.loadProfiles();
        })
    }

    onSearch = (search) => {
        this.setState({ page: 1, search }, () => {
            this.loadProfiles();
        })
    }

    componentDidMount() {
        this.loadProfiles();
    }

    render() {
        const {list, loading} = this.state;
        return (
            <div className={`controller profiles`}>
                <PageHeader title={`Список профилей`} className={`page-header`}/>
                <Table
                    bordered
                    title={() => {
                        return (
                            <div className={`table_actions`}>
                                <Button type={`primary`} onClick={this.createOutputRedirect}>Создать</Button>
                                <Input.Search placeholder={`Введите названи`} onSearch={this.onSearch}/>
                            </div>
                        );
                    }}
                    columns={this.columns}
                    rowKey={row => row.id}
                    dataSource={list}
                    loading={loading}
                    pagination={{
                        pageSize: this.state.limit,
                        defaultCurrent: this.state.page,
                        total: this.state.total,
                        onChange: this.onPageChange,
                        current: this.state.page,
                    }}
                />
            </div>
        );
    }
}