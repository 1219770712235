import React from 'react';
import {Alert, Button, Checkbox, Popconfirm, message, PageHeader, Table, Spin, Modal} from 'antd';
import Fetcher from './../../library/fetcher';
import Moment from 'moment';
import {EditOutlined} from '@ant-design/icons';
import {Link} from "react-router-dom";
import Config from "../../config";



export class ProfileView extends React.Component {


    constructor(props) {
        super(props);
        const params = this.props.match && this.props.match.params ? this.props.match.params : {};
        this.state = {
            loading: params.id ? true : false,
            id: params.id || false,
            profile: false,
        };
    }

    loadProfile = () => {
        this.setState({ loading: true }, () => {
            Fetcher(`profiles/${this.state.id}`, `GET`).then(res => {
                const data = res.data || {};
                console.log(data);
                this.setState({ profile: data, id: data.id || false, loading: false});
            }).catch(e => {
                message.error(`Ошибка загрузки пользователя`);
                console.error(`profile loading error: `, e);
                this.setState({ loading: false, id: false });
            })
        });
    }


    componentDidMount() {
        if (this.state.id) {
            this.loadProfile();
        }
    }

    onEdit = (id) => {
        this.props.history.push(`/questions/${id}`);
    }


    render() {
        const { loading, id, profile } = this.state;
        return (
            <div className={`controller profiles`}>
                <Spin spinning={loading}>
                    {id === false && <Alert message={`Ошибка загрузки профиля`} type={`error`} />}
                    {id !== false && (
                        <React.Fragment>
                            <PageHeader title={`Все видео профиля ${profile.email || ""}`} className={`page-header`} onBack={() => {
                                this.props.history.push('/profiles');
                            }}/>
                            <RenderQTable
                                id={this.state.id}
                                onEdit={this.onEdit}
                            />
                        </React.Fragment>
                    )}

                </Spin>
            </div>
        );
    }
}


class RenderQTable extends React.Component {
    columns = [
        {
            title: 'Дата загрузки',
            key: 'createAt',
            align: 'left',
            render: r => Moment(r.createdAt).format(`YYYY-MM-DD hh:mm:ss`)
        },
        {
            title: 'Вопрос',
            key: 'question',
            render: r => {
                return <a href={'#item'} onClick={(e) => {
                    e.preventDefault();
                    this.showModal(r);
                }}>Видео</a>;
            }
        },
        {
            title: 'Выпуск',
            key: 'output',
            render: r => {
               return <Link to={`/output/view/${r.output.id}`}>{r.output.title}</Link>;
            }
        },
        {
            title: 'Проверен',
            key: 'approved',
            align: 'center',
            width: '140px',
            render: r => {
                return <React.Fragment>
                    <Checkbox checked={r.approved} onChange={(e) => {
                        this.updateQuestions(r.id, 'approved', e.target.checked);
                    }}/>
                </React.Fragment>
            }
        },
        {
            title: 'Действия',
            key: 'in_export',
            render: r => {
                return (
                    <div className={`actions_wrp`}>
                        <Button type={`primary`} onClick={() => this.props.onEdit(r.id)} icon={<EditOutlined />}/>
                        <Popconfirm title={`Вы уверены, что хотите удалить вопрос?`}><Button type={`danger`}>Удалить</Button></Popconfirm>
                    </div>
                );
            }
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            list: [],
            total: 0,
            page: 1,
            limit: 100,
            modal_title: '',
            modal_video: '',
        };
    }

    updateQuestions = (id, field, status) => {
        this.setState({ loading: true }, () => {
            let sendData = {};
            sendData[field] = status;
            Fetcher(`/questions/${id}`, `PUT`, sendData).then(res => {
                this.props.reload();
            }).catch(e => {
                message.error(`Ошибка обновления вопрос`);
                console.error(e);
                this.setState({ loading: false });
            });
        })
    }

    onPageChange = (page) => {
        this.setState({page}, () => {
            this.load();
        })
    }

    load = () => {
        const {id} = this.props;
        this.setState({loading: true}, () => {
            Fetcher(`questions?page=${this.state.page}&limit=${this.state.limit}&user_id=${id}`).then(res => {
                const data = res.data || {};
                this.setState({loading: false, list: data.list || [], total: data.total || 0});
            }).catch(e => {
                message.error(`Ошибка загрузки вопросов`);
                console.error(`error load question list`, e, this.props);
                this.setState({loading: false});
            });
        });
    }

    reload = () => {
        this.load();
    }

    componentDidMount() {
        this.load();
    }

    showModal = (r) => {
        this.setState({
            modal_title: r.user.email,
            modal_video: `${Config.STATIC}${r.video_file}`,
        })
    }

    closeModal = () => {
        this.setState({ modal_title: '', modal_video: ''});
    }

    render() {
        return (
            <React.Fragment>
                <Modal title={`Вопрос от пользователя ${this.state.modal_title}`} visible={this.state.modal_title !== ""} footer={null} onCancel={this.closeModal}>
                    <video src={this.state.modal_video} controls={true} style={{width: '100%', height: 'auto', maxHeight: '400px', margin: '0 auto'}}/>
                </Modal>
                <Table
                    border
                    loading={this.state.loading}
                    columns={this.columns}
                    rowKey={row => row.id}
                    dataSource={this.state.list}
                    pagination={{
                        pageSize: this.state.limit,
                        defaultCurrent: this.state.page,
                        total: this.state.total,
                        onChange: this.onPageChange,
                        current: this.state.page,
                    }}
                />
            </React.Fragment>
        );
    }
}