import Config from "./../config";
import AppStore from './store';

// make request to api
const Request = (host, url, method = 'GET', data = null, headers, refresh = false) => {
    return new Promise((resolve, reject) => {
        let _headers = {
            'Content-Type': 'application/json'
        };

        if (AppStore.get(`${Config.TOKEN.AUTH}`)) {
            _headers['Authorization'] = AppStore.get(`${Config.TOKEN.AUTH}`);
        }

        if (refresh === true && AppStore.get(`${Config.TOKEN.REFRESH}`)) {
            _headers['Authorization'] = AppStore.get(`${Config.TOKEN.REFRESH}`);
        }

        if (headers) {
            if (headers['Content-Type']){
                _headers['Content-Type'] = headers['Content-Type'];
            }
            _headers = {..._headers, headers};
        }


        let options = {
            method: method,
            body: data !== null ? JSON.stringify(data) : null,
            cors: true,
            headers: _headers
        };


        let good_codes = [200, 201];
        if (url[0] === '/') {
            url = url.substr(1, url.length - 1);
        }

        fetch(`${host}/${url}`, options)
            .then(async (res) => {
                if(good_codes.indexOf(res.status) + 1 === 0) {
                    try {
                        let jsonError = await res.json(res);
                        reject({status: res.status, statusText: res.statusText, answer: jsonError});
                    } catch (e) {
                        reject({status: res.status, statusText: res.statusText});
                    }
                } else {
                    return res.json(res)
                }
            })
            .then(json => resolve(json))
            .catch(err => reject(err));
    });
};

// make request to project api server
export default (url, method = 'GET', data = null, headers, refresh = false) => {
    return Request(Config.API, url, method, data, headers, refresh)
};

// make request to oauth api
export const OAuthRequest = (url, method = 'GET', data = null, headers, refresh = false) => {
    return Request(Config.OAUTH.API, url, method, data, headers, refresh)
};
//
// export const ElasticFetcher = (url, method = 'GET', data = null, headers, refresh = false) => {
//     return Request(Config.ELASTIC.API, url, method, data, headers, refresh);
// };
