import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { Layout, Menu} from 'antd';

import { IndexController } from './../controllers/Index.controller';
import { OutputCreateController } from './../controllers/Outputs/Create.controller';
import { ProfileIndex } from './../controllers/Profiles/Index.controller';
import { QuestionsIndex } from './../controllers/Questions/Index.controller';
import { QuestionsEdit } from './../controllers/Questions/Edit.controller';
import { OutputViewController } from './../controllers/Outputs/View.controller';
import { ProfileView } from './../controllers/Profiles/View.controller';

export default class AppLayout extends React.Component {
    render() {
        let key = '1';

        if (window.location.pathname.indexOf('/questions') + 1 > 0) {
            key = '2';
        }

        if (window.location.pathname.indexOf('/profiles') + 1 > 0) {
            key = '3';
        }

        return (
            <Router>
                <Layout>
                    <Layout.Header className={`layout_header`}>
                        <div className="logo">
                            <Link to={'/'}>
                                <svg><use xlinkHref="#logo"></use></svg>
                            </Link>
                        </div>
                        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[key]}>
                            <Menu.Item key={`1`}><Link to={'/'}>Выпуски</Link></Menu.Item>
                            <Menu.Item key={`2`}><Link to={'/questions'}>Вопросы</Link></Menu.Item>
                            <Menu.Item key={`3`}><Link to={'/profiles'}>Пользователи</Link></Menu.Item>
                        </Menu>
                    </Layout.Header>
                    <Layout.Content>
                        <Switch>
                            <Route path={`/`} exact render={(p) => <IndexController {...p} {...this.props}/>} />
                            <Route path={`/output/create`} exact render={(p) => <OutputCreateController {...p} {...this.props}/>} />
                            <Route path={`/output/view/:id`} exact render={(p) => <OutputViewController {...p} {...this.props}/>} />
                            <Route path={`/output/:id`} exact render={(p) => <OutputCreateController {...p} {...this.props}/>} />
                            <Route path={`/profiles`} exact render={(p) => <ProfileIndex {...p} {...this.props}/>} />
                            <Route path={`/profiles/:id`} exact render={(p) => <ProfileView {...p} {...this.props}/>} />
                            <Route path={`/questions`} exact render={(p) => <QuestionsIndex {...p} {...this.props}/>} />
                            <Route path={`/questions/:id`} exact render={(p) => <QuestionsEdit {...p} {...this.props}/>} />
                        </Switch>
                    </Layout.Content>
                </Layout>
            </Router>
        );
    }
}