import React from 'react';
import {Button, Checkbox, Input, message, PageHeader, Popconfirm, Table} from 'antd';
import Fetcher from './../library/fetcher';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import {DeleteOutlined, EditOutlined, EyeOutlined} from '@ant-design/icons';


export class IndexController extends React.Component {

    columns = [
        {
            title: 'Название',
            key: 'title',
            render: r => {
                return <Link to={`/outputs/${r.id}`}>{r.title || '[не установлено]'}</Link>
            }
        },
        {
            title: 'Опубликован',
            key: 'active',
            align: 'center',
            render: r => {
                return <Checkbox checked={r.active} onChange={(e) => {
                    this.updateOutputStatus(r.id, e.target.checked);
                }}/>;
            }
        },
        {
            title: 'Получение вопросов',
            key: 'questions_to',
            align: 'center',
            render: r => {
                return Moment(r.questions_to).format('YYYY-MM-DD');
            }
        },
        {
            title: 'Дата публикации',
            key: 'published_at',
            align: 'center',
            render: r => {
                return Moment(r.published_at).format('YYYY-MM-DD hh:mm:ss');
            }
        },
        {
            title: 'Youtube',
            key: `youtube`,
            align: "center",
            render: r => {
                if (r.youtube_video_link !== '') {
                    return <a href={r.youtube_video_link} target={"_blank"} rel={'noopener noreferrer'}>
                        Посмотреть
                    </a>
                }
                return '[не задана]';
            }
        },
        {
            title: '',
            key: 'actions',
            render: r => {
                return (
                    <div className={`actions_wrp`}>
                        <Button type={`primary`} icon={<EyeOutlined/>} onClick={() => this.props.history.push('/output/view/' + r.id)}/>
                        <Button type={`primary`} icon={<EditOutlined/>} onClick={() => this.props.history.push('/output/' + r.id)}/>
                        <Popconfirm title={`Вы уверены, что хотите удалить выпуск?`} onConfirm={() => this.deleteItem(r.id)}>
                            <Button type={`danger`} icon={<DeleteOutlined/>}/>
                        </Popconfirm>
                    </div>

                );
            }
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            limit: 30,
            total: 0,
            search: '',
            loading: false,
            list: [],
        };
    }

    updateOutputStatus = (id, status) => {
        this.setState({ loading: true }, () => {
            Fetcher(`outputs/${id}`, `PUT`, {active: status}).then(res => {
                let list = [...this.state.list];
                list = list.map(i => {
                    if (i.id === id) {
                        i.active = status;
                    }
                    return i;
                });
                message.success(`Выпуск успешно обновлен`);
                this.setState({ loading: false, list });
            }).catch(e => {
                console.error(`error update output: `, e)
                message.error(`Ошибка обновления выпуска`);
                this.setState({ loading: false });
            });
        })
    }

    deleteItem = (id) => {
        this.setState({loading: true}, () => {
            Fetcher(`outputs/${id}`, `DELETE`).then(res => {
                message.success(`Выпуск успешно удален`);
                this.loadList();
            }).catch(e => {
                console.error(`error delete output: `, e);
                this.setState({ loading: false });
                message.error(`Ошибка удаление выпуска, обратитесь пожалуйста к разработчикам`);
            });
        })
    }

    onPageChange = (page) => {
        this.setState({ page }, () => {
            this.loadList();
        })
    }

    onSearch = (search) => {
        this.setState({ page: 1, search }, () => {
            this.loadList();
        })
    }

    loadList = () => {
        this.setState({loading: true}, () => {
            const {page, limit, search} = this.state;
            Fetcher(`outputs?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}`).then(res => {
                const data = res.data || {};
                this.setState({
                    loading: false,
                    list: data.list || [],
                    total: data.total || 0,
                });
            }).catch(e => {
                message.error(`Во время загрузки возникла ошибка, попробуйте пожалуйста позднее`);
                this.setState({loading: false});
            });
        })
    }

    createOutputRedirect = () => {
        this.props.history.push(`/output/create`);
    }

    componentDidMount() {
        this.loadList();
    }

    render() {
        const {loading, list} = this.state;
        return (
            <div className={`controller index`}>
                <PageHeader title={`Список выпусков`} className={`page-header`}/>
                <Table
                    bordered
                    title={() => {
                        return (
                            <div className={`table_actions`}>
                                <Button type={`primary`} onClick={this.createOutputRedirect}>Создать</Button>
                                <Input.Search placeholder={`Введите название выпуска`} onSearch={this.onSearch}/>
                            </div>
                        );
                    }}
                    columns={this.columns}
                    rowKey={row => row.id}
                    dataSource={list}
                    loading={loading}
                    pagination={{
                        pageSize: this.state.limit,
                        defaultCurrent: this.state.page,
                        total: this.state.total,
                        onChange: this.onPageChange,
                        current: this.state.page,
                    }}
                />
            </div>
        );
    }
}
