import React from 'react';
import Fetcher from './../../library/fetcher';
import {Link} from 'react-router-dom';
import {Alert, Avatar, message, PageHeader, Spin, Table, Modal, Checkbox, Button, Popover, Input} from 'antd';
import Config from './../../config';
import { EditOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';

export class OutputViewController extends React.Component {
    constructor(props) {
        super(props);
        const params = this.props.match && this.props.match.params ? this.props.match.params : false;
        this.state = {
            id: params.id || false,
            loading: true,
            output: false,
        };
        this.notExportRef = React.createRef();
        this.ExportRef = React.createRef();
    }

    loadOutput = () => {
        this.setState({loading: true}, () => {
            Fetcher(`outputs/${this.state.id}`, `GET`).then(res => {
                const data = res.data || {};
                this.setState({
                    loading: false,
                    item: data,
                });
            }).catch(e => {
                console.error(`error load output: `, e);
                this.setState({loading: false, id: false});
            });
        })
    }

    componentDidMount() {
        if (this.state.id !== false) {
            this.loadOutput();
        }
    }

    reload = () => {
        this.notExportRef.current.reload();
        this.ExportRef.current.reload();
    }

    onEdit = (id) => {
        this.props.history.push(`/questions/${id}`);
    }

    render() {
        const {loading, id} = this.state;
        return (
            <div className={`controller output_view`}>
                <PageHeader title={`Информация о выпуске`} className={`page-header`} onBack={() => {
                    window.history.back();
                }}/>
                <Spin spinning={loading} tip={`Идет загрузка`}>
                    {id === false && <Alert message={`Ошибка загрузки выпуска`} type={`error`}/>}
                    {id !== false && loading === false && (
                        <React.Fragment>
                            <div className="export_link">
                                <h3>Ссылка для выгрузки</h3>
                                <a href={`${Config.EXPORT_URL}/${this.state.id}`}>{`${Config.EXPORT_URL}/${this.state.id}`}</a>
                                <h3>Ссылка для программы</h3>
                                <a href={`${Config.EXPORT_URL_JSON}/${this.state.id}`}>{`${Config.EXPORT_URL_JSON}/${this.state.id}`}</a>
                            </div>
                            <div className="questions_export">
                                <div className="column">
                                    <RenderQTable ref={this.notExportRef} id={this.state.id} isExport={false} reload={this.reload} onEdit={this.onEdit}/>
                                </div>
                                <div className="column">
                                    <RenderQTable ref={this.ExportRef} id={this.state.id} isExport={true} reload={this.reload} onEdit={this.onEdit}/>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </Spin>
            </div>
        );
    }
}

class RenderQTable extends React.Component {
    columns = [
        {
          title: '#',
          key: 'number',
          align: `left`,
          render: (r, item, index) => {
              return (
                  <div className={`sort_actions`} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      {this.props.isExport && (
                          <div className="sort" style={{display: 'flex', flexDirection: 'column', marginRight: '8px'}}>
                              {index > 0 && <Button onClick={() => {this.updateQuestionsSort(r.id, "up")}} icon={<UpOutlined />} size={`small`}/>}
                              {index < this.state.list.length -1 && (<Button onClick={() => {this.updateQuestionsSort(r.id, "down")}} icon={<DownOutlined />} size={`small`}/>)}
                          </div>
                      )}
                      <div className="number">{r.number || ''}</div>
                  </div>
              )
          },
        },
        {
            title: 'Пользователь',
            key: 'user',
            align: 'left',
            render: r => (
                <Link to={`/profiles/${r.user.id}`} className={`q_user`}>
                    {r.user && r.user.image && <Avatar src={r.user.image}  size={24} style={{minWidth: 24}}/>}
                    <span>{r.user.full_name}</span>
                </Link>
            )
        },
        {
            title: 'Вопрос',
            key: 'question',
            render: r => {
                if (r.video_file) {
                    return <a href={'#item'} onClick={(e) => {
                        e.preventDefault();
                        this.showModal(r);
                    }}>Видео</a>;
                } else {
                    return (
                        <Popover overlayStyle={{maxWidth: 400}} title={`Текст вопроса`} content={r.text}>{r.shortcut.length > 100 ? r.shortcut.substr(0, 97) + '...' : r.shortcut}</Popover>
                    );
                }
            }
        },
        {
            title: 'Проверен',
            key: 'approved',
            align: 'center',
            width: '140px',
            render: r => {
                return <React.Fragment>
                    <Checkbox checked={r.approved} onChange={(e) => {
                        this.updateQuestions(r.id, 'approved', e.target.checked);
                    }}/>
                </React.Fragment>
            }
        },
        {
            title: 'Действия',
            key: 'in_export',
            render: r => {
                if (this.props.isExport) {
                    return (
                    <div className={`actions_wrp`}>
                        <Button type={`primary`} onClick={() => this.props.onEdit(r.id)} icon={<EditOutlined />}/>
                        <Button type={`danger`} onClick={() => {
                            this.updateQuestions(r.id, 'in_export', false);
                        }}>Убрать</Button>
                    </div>);
                } else {
                    return (
                        <div className={`actions_wrp`}>
                            <Button type={`primary`} onClick={() => this.props.onEdit(r.id)} icon={<EditOutlined />}/>
                            <Button type={`primary`} onClick={() => {
                                this.updateQuestions(r.id, 'in_export', true);
                             }}>Добавить</Button>
                        </div>
                    )
                }
            }
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            list: [],
            total: 0,
            page: 1,
            limit: 100,
            search: '',
            approved: false,
            modal_title: '',
            modal_video: '',
        };
    }

    updateQuestionsSort = (qId, act) => {
        let list = [...this.state.list];
        let index = false;
        list.forEach((o, i) => {
           if (o.id === qId) {
               index = i;
           }
        });

        if (index !== false) {
            if (act === 'up' && index > 0) {
                let buff = list[index];
                list[index] = list[index - 1];
                list[index - 1] = buff;
            }
            if (act === 'down' && index < list.length - 1) {
                let buff = list[index];
                list[index] = list[index + 1];
                list[index + 1] = buff;
            }
        }

        this.setState({ loading: true }, () => {
            let sortArr = list.map((i, index) => {
                return {id: i.id, sort: index + 1}
            });
            Fetcher(`/questions/sort`, `PUT`, {list: sortArr}).then(res => {
                this.load();
            }).catch(e => {
                message.error(`Ошибка обновления сортировки`);
                this.setState({ loading: false });
            })
        })

        // this.setState({ list });
    }

    updateQuestions = (id, field, status) => {
        this.setState({ loading: true }, () => {
            let sendData = {};
            sendData[field] = status;
            if (field === 'in_export') {
                sendData['output'] = this.props.id;
            }
            Fetcher(`/questions/${id}`, `PUT`, sendData).then(res => {
               this.props.reload();
            }).catch(e => {
                message.error(`Ошибка обновления вопрос`);
                console.error(e);
                this.setState({ loading: false });
            });
        })
    }

    onPageChange = (page) => {
        this.setState({page}, () => {
            this.load();
        })
    }

    load = () => {
        const {isExport, id} = this.props;
        this.setState({loading: true}, () => {

            let url = `questions?page=${this.state.page}&limit=${this.state.limit}&in_export=${isExport}&search=${encodeURIComponent(this.state.search)}`;
            if (this.state.approved) {
                url = `${url}&approved=${this.state.approved}`;
            }
            if (isExport) {
                url = `${url}&output_id=${id}`;
            }
            Fetcher(url).then(res => {
                const data = res.data || {};
                this.setState({loading: false, list: data.list || [], total: data.total || 0});
            }).catch(e => {
                message.error(`Ошибка загрузки вопросов`);
                console.error(`error load question list`, e, this.props);
                this.setState({loading: false});
            });
        });
    }

    reload = () => {
        this.load();
    }

    componentDidMount() {
        this.load();
    }

    showModal = (r) => {
        this.setState({
            modal_title: r.user.email,
            modal_video: `${Config.STATIC}${r.video_file}`,
        })
    }

    closeModal = () => {
        this.setState({ modal_title: '', modal_video: ''});
    }

    onSearch = (search) => {
        this.setState({ search, page: 1 }, () => {
            this.load();
        })
    }

    render() {
        return (
            <React.Fragment>
                <Modal title={`Вопрос от пользователя ${this.state.modal_title}`} visible={this.state.modal_title !== ""} footer={null} onCancel={this.closeModal}>
                    <video src={this.state.modal_video} controls={true} style={{width: '100%', height: 'auto', maxHeight: '400px', margin: '0 auto'}}/>
                </Modal>
                <Table
                    border
                    title={() => {
                        return (
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Input.Search placeholder={`Введите запрос`} onSearch={this.onSearch}/>
                                {this.props.isExport === false && (
                                    <Checkbox
                                        checked={this.state.approved}
                                        onChange={(e) => {
                                            let approved = e.target.checked;
                                            this.setState({ approved }, () => {
                                                this.load();
                                            })
                                        }}
                                        style={{flexDirection: "row", alignItems: "center", display: "flex", marginLeft: 12}}>Только проверенные</Checkbox>)}
                            </div>

                        );
                    }}
                    loading={this.state.loading}
                    columns={this.columns}
                    rowKey={row => row.id}
                    dataSource={this.state.list}
                    pagination={{
                        pageSize: this.state.limit,
                        defaultCurrent: this.state.page,
                        total: this.state.total,
                        onChange: this.onPageChange,
                        current: this.state.page,

                    }}
                />
            </React.Fragment>
        );
    }
}