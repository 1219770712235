import React from 'react';
import {Alert, Avatar, Button, Checkbox, Form, Input, message, PageHeader, Spin, Upload} from 'antd';
import Fetcher from './../../library/fetcher';
import Moment from 'moment';
import {InboxOutlined} from '@ant-design/icons';
import Config from './../../config';
import Store from './../../library/store';
import { Link } from 'react-router-dom';

const AllowedTypes = 'image/jpg,image/png,image/jpeg';


export class QuestionsEdit extends React.Component {

    constructor(props) {
        super(props);
        const params = this.props.match && this.props.match.params ? this.props.match.params : false;
        this.state = {
            id: params.id || false,
            loading: true,
            formLoading: false,
            item: {},
        }
    }

    loadQuestion = () => {
        this.setState({loading: true}, () => {
            Fetcher(`questions/${this.state.id}`).then(res => {
                const data = res.data || {};
                this.setState({
                    loading: false,
                    item: data,
                    id: data.id || false,
                })
            }).catch(e => {
                console.error(`error load question: `, e)
                message.error(`Ошибка загрузки вопроса`);
                this.setState({id: false, loading: false});
            });
        })
    }

    componentDidMount() {
        if (this.state.id !== false) {
            this.loadQuestion();
        }
    }

    uploadVideoCover = (cover) => {
        this.setState({ formLoading: true }, () => {
            let formData = new FormData();
            formData.append(`files`, cover)
            fetch(`${Config.API}/questions/${this.state.id}/cover`, {
                method: 'POST',
                headers: {
                    'Authorization': Store.get(Config.TOKEN.AUTH),
                },
                body: formData,
            }).then(res => res.json()).then(res => {
                const data = res.data || {};
                this.setState({ formLoading: false, item: data || {}, id: data.id || false})
            }).catch(e => {
                this.setState({ formLoading: false });
                message.error(`Во время загрузки возникла ошибка`);
                console.error(e);
            })
        });
    }

    updateCover = (cover) => {
        let item = {...this.state.item};
        item.cover_file = cover;
        this.setState({ item });
    }


    onFinish = (values) => {
        let sendData = {
            text: values.text,
            shortcut: values.shortcut,
            cover_file: this.state.item.cover_file
        };

        if (values.approved !== "undefined") {
            sendData.approved = values.approved;
        }

        if (values.in_export !== "undefined") {
            sendData.in_export = values.in_export;
        }

        this.setState({ formLoading: true }, () => {
            Fetcher(`questions/${this.state.id}`, `PUT`, sendData).then(res => {
                message.success(`Вопрос успешно обновлен`);
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            }).catch(e => {
                console.error(`question update error: `, e);
                message.error(`Возникла ошибка во время обновления вопроса`);
                this.setState({ formLoading: false });
            });
        })
    }

    render() {
        const {item} = this.state;
        return (
            <div className={`controller question_edit`}>
                {this.state.id === false && <Alert message={`Вопрос не найден`} type={`error`}/>}
                {this.state.id !== false && this.state.loading === true && (
                    <Spin spinning={true} tip={`Идет загрузка`}/>
                )}
                {this.state.id !== false && this.state.loading === false && (
                    <React.Fragment>
                        <PageHeader title={`Редактирование вопроса от ${item.user.email} / Номер вопроса ${item.number || ''}`} onBack={() => {
                            this.props.history.push('/questions');
                        }} className={`page-header`}/>
                        {item.transcode_status === 'finished' &&
                        <BlockInfo
                            {...item}
                            formLoading={this.state.formLoading}
                            uploadVideoCover={this.uploadVideoCover}
                            onFinish={this.onFinish}
                            updateCover={this.updateCover}
                        />}
                        {item.transcode_status !== 'finished' &&
                        <Alert message={`Идет обработка вопроса`} type={`info`}/>}

                    </React.Fragment>
                )}
            </div>
        );
    }
}


const BlockInfo = (props) => {
    const {user, output, createdAt, video_file, cover_file, covers, updateCover, approved, in_export, text, shortcut} = props;

    let selected_cover = cover_file;
    if (!selected_cover && Array.isArray(covers) && covers[0]) {
        selected_cover = covers[0];
    }

    return (
        <div className={`question_info`}>
            <Spin spinning={props.formLoading}>
                <Form layout={`vertical`} onFinish={props.onFinish}>
                    <div className={`question_row user`}>
                        <div className={`title`}>Пользователь</div>
                        <div className={`value`}>
                            <Link to={`/profiles/${user.id}`}>
                                {user.image && <Avatar size={48} src={user.image}/>}
                                {user.full_name && <span>{user.full_name} [{user.email}]</span>}
                            </Link>
                        </div>
                    </div>
                    <div className={`question_row output`}>
                        <div className={`title`}>Выпуск</div>
                        <div className="value">
                            <Link to={`/output/view/${output.id}`}>
                                {output && output.title ? output.title : `[not set]`}
                            </Link>
                        </div>
                    </div>
                    <div className={`question_row created_at`}>
                        <div className={`title`}>Дата загрузки</div>
                        <div className="value">{Moment(createdAt).format('YYYY-MM-DD hh:mm:ss')}</div>
                    </div>
                    <div className={`question_row active`}>
                        <div className={`title`}>Опубликован на сайте</div>
                        <div className="value">
                            <Form.Item initialValue={approved} name={`approved`} valuePropName="checked"><Checkbox/></Form.Item>
                        </div>
                    </div>
                    <div className="question_row info_questions">
                        {video_file && (
                            <div className="question_video">
                                <video src={`${Config.STATIC}${video_file}`} controls/>
                                <Button type={`primary`} onClick={() => {
                                    window.open(`${Config.STATIC}${video_file}`);
                                }}>Открыть в новой вкладке</Button>
                            </div>
                        )}

                        <div className="question_fields">
                            <div className={`question_row shortcut`}>
                                <Form.Item initialValue={shortcut} name={`shortcut`} label={`Короткое описание`}
                                           rules={[{required: true, message: `Заполните короткое описание`}]}>
                                    <Input.TextArea/>
                                </Form.Item>
                            </div>
                            <div className={`question_row text`}>
                                <Form.Item initialValue={text} name={`text`} label={`Полный текст вопроса`}
                                           rules={[{required: true, message: `Заполните полный текст вопроса`}]}>
                                    <Input.TextArea/>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className="question_row cover">
                        <div className={`cover`}>
                            {selected_cover && <img src={`${Config.STATIC}${selected_cover}`} alt={`video cover`}/>}
                        </div>
                        <div className="covers_list">
                            <Form.Item name={`cover_file`} initialValue={selected_cover} style={{display: 'none'}}>
                                <Input />
                            </Form.Item>
                            <Upload.Dragger multiple={false} accept={AllowedTypes} listType={false} showUploadList={false} beforeUpload={(files) => {
                                props.uploadVideoCover(files);
                                return false;
                            }}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined/>
                                </p>
                                <p className="ant-upload-text">Кликните или перетащите файл для загрузки</p>
                            </Upload.Dragger>
                            <div className="list">
                                {covers && covers.length > 0 && covers.map(i => {
                                    return <div key={`item_${i}`} onClick={() => {
                                        updateCover(i)
                                    }} className={`image`} style={{backgroundImage: `url(${Config.STATIC}${i})`}}/>
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="question_row btns">
                        <Button type={`primary`} htmlType={`primary`}>Сохранить</Button>
                    </div>
                </Form>
            </Spin>
        </div>
    );
}